export const GQL_FETCH_HUMANTRAK_PAGE_BANNER_ENTRY = `
    heroBanner: pageComponentsCollection(where: { sys: { id: "6fKuz9q3gJk8pSRe5O2Z9E" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_ACCESSIBILITY_ENTRY = `
    accessibility: pageComponentsCollection(
      where: {sys: {id: "dXBEnn2Pzvph3qGeseaO3"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_POWERFUL_ENTRY = `
    powerful: pageComponentsCollection(
      where: {sys: {id: "3ascGwv06uU2vUAXUA22oC"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_CONTEXT_ENTRY = `
    context: pageComponentsCollection(
      where: {sys: {id: "5zux0kAIybd7QYqvt85A06"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_ENGAGEMENT_ENTRY = `
    engagement: pageComponentsCollection(
      where: {sys: {id: "59DQIcl8dn8Tb0eproDhtc"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_KIT_ENTRY = `
    humanTrakKit: pageComponentsCollection(
      where: {sys: {id: "1wwaQOopCPIfyUvD67M6ju"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
          mediaPrimary {
            url
          }
          extraAssetsCollection {
            items {
              ... on Card {
                mediaPrimary {
                  url
                }
              }
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_FLEXIBILITY_ENTRY = `
    flexibility: pageComponentsCollection(
      where: {sys: {id: "6CabHeDLDsb7QGfIaKiELs"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_RELATED_ENTRY = `
 relatedSystems: pageComponentsCollection(where: { sys: { id: "47jsWcqFzNtdPXnQJb588w" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }   
  }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "3REB9nr3GyLYRkklmzbfc5"}}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "2vt3VPhQYy0DmHNq415ubX" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
