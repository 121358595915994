export const GQL_FETCH_CLINICIANS_PAGE_HERO_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "7xeoG72JUfbBqFi5cjPz5c" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_ENTRY = `
  clinicalSetting: pageComponentsCollection(where: {sys: {id: "DFfVVK30d0fbxv78RckwZ"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_CAROUSEL_ENTRY = `
  clinicalSettingCarousel: pageComponentsCollection(where: {sys: {id: "2xV46fArJClT0d0UWxowei"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        title
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              preTitle
              title
              mediaPrimary {
                width
                height
                url
                description
              }
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_FOR_CLINICIANS_ENTRY = `
  forClinicians: pageComponentsCollection(where: {sys: {id: "3KXUglQtcsdXqusDiweugW"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_WE_EXIST_ENTRY = `
  weExist: pageComponentsCollection(where: { sys: { id: "1vYfVCi8Si7VJ6vF5cPMA2" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_CLIENT_SUCCESS_ENTRY = `
  clientSuccess: pageComponentsCollection(where: {sys: {id: "2AS0ZlxkGoBpq6e2NpVeeg"}}, limit: 1) {
    items {
      ... on Grid {
        bodyCopy {
          json
        }
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_MORE_TIME_ENTRY = `
  moreTime: pageComponentsCollection(where: { sys: { id: "3AtuupCIttmxSab6JeUehN" } }, limit: 1) {
    items {
			... on DuplexComponent {
        title
        bodyCopy {
          json
        }
        scrollTo
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_TAILORED_CARE_ENTRY = `
  tailoredCare: pageComponentsCollection(where: { sys: { id: "yyBKqdgQ9CZb4m7rmsTXW" } }, limit: 1) {
    items {
			... on DuplexComponent {
        title
        bodyCopy {
          json
        }
        scrollTo
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_VALD_TECHNOLOGY_ENTRY = `
  valdTechnology: pageComponentsCollection(where: { sys: { id: "50U6d4fKr0mfuhRLrYc7Co" } }, limit: 1) {
    items {
      ... on SectionHeader {
       title
        extraAssetsCollection {
          items {
            ... on Video {
              type
              url
              description
              thumbnail {
                url
                description
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "7BA92pQ2mkuiWJKOzInfJz" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
